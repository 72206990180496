<template>
  <div :class="{'opacity-30': !getSaveLink.is_ab_test}" v-if="toggle">
    <div class="flex justify-between items-center">
      <p class="text !text-[#202324] font-bold">A/B Testing</p>
      <div class="flex space-x-1 items-center" v-if="!isValidWeight(split_urls) && toggle">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-[1rem] h-[1rem]" viewBox="0 0 17 17" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.02273 1.5C4.42032 1.5 1.5 4.42032 1.5 8.02273C1.5 11.6251 4.42032 14.5455 8.02273 14.5455C11.6251 14.5455 14.5455 11.6251 14.5455 8.02273C14.5455 4.42032 11.6251 1.5 8.02273 1.5ZM0 8.02273C0 3.5919 3.5919 0 8.02273 0C12.4536 0 16.0455 3.5919 16.0455 8.02273C16.0455 12.4536 12.4536 16.0455 8.02273 16.0455C3.5919 16.0455 0 12.4536 0 8.02273ZM8.02273 4.36364C8.43694 4.36364 8.77273 4.69942 8.77273 5.11364V8.02273C8.77273 8.43694 8.43694 8.77273 8.02273 8.77273C7.60851 8.77273 7.27273 8.43694 7.27273 8.02273V5.11364C7.27273 4.69942 7.60851 4.36364 8.02273 4.36364ZM8.02273 10.1818C7.60851 10.1818 7.27273 10.5176 7.27273 10.9318C7.27273 11.346 7.60851 11.6818 8.02273 11.6818H8.03C8.44421 11.6818 8.78 11.346 8.78 10.9318C8.78 10.5176 8.44421 10.1818 8.03 10.1818H8.02273Z" fill="#E8B34D"/>
        </svg>
        <p class="text-[0.75rem] font-poppins text-[#3C4549]">Weight Sum Should be 100</p>
      </div>
    </div>
    <div class="mt-[1rem]">
       <div :key="item.id ? item.id : index" v-for="(item,index) in split_urls" class="flex gap-x-2 border-b border-[#ECEEF5] py-[0.5rem] items-center">
        <div class="w-full">
          <URLInputFloatingV2
          id="url"
          v-model="item.url"
          @input="onChangeUrLField($event,item)"
          :validations="item.validations"
          :disabled="index == 0 || !getSaveLink.is_ab_test"
          type="text"
          label="URl"
          customClass="h-[3.5rem] bg-[#F4F6FA]"
          :errorType="item.validations.url || item.validations.urlValid || item.validations.urlLength || item.validations.iframe  ? 'danger' : ''"
        >
          <template v-slot:error_message>
            <InputFieldMessage v-if="item.validations.url" :message="abTestingObjMessages.url"></InputFieldMessage>
            <InputFieldMessage v-else-if="item.validations.urlLength" :message="abTestingObjMessages.urlLength"></InputFieldMessage>
            <InputFieldMessage v-else-if="item.validations.urlValid" :message="abTestingObjMessages.urlValid"></InputFieldMessage>
            <InputFieldMessage v-else-if="item.validations.iframe" :message="abTestingObjMessages.iframe"></InputFieldMessage>
          </template>
          <template v-slot:right_icon>
            <Loader v-if="index!=0 && item.loader" color="text-[#2560d7]"></Loader>
            <svg v-if="index != 0" @click="removeABTestingObject(index)" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer w-[1rem] h-[1rem]" viewBox="0 0 16 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.80735 2.02094C5.96582 1.86247 6.18076 1.77344 6.40487 1.77344H9.5949C9.81901 1.77344 10.0339 1.86247 10.1924 2.02094C10.3509 2.17941 10.4399 2.39434 10.4399 2.61845V3.4625H5.55985V2.61845C5.55985 2.39434 5.64888 2.17941 5.80735 2.02094ZM4.7713 4.96249C4.78407 4.96314 4.79692 4.96347 4.80985 4.96347C4.82278 4.96347 4.83564 4.96314 4.8484 4.96249H11.1514C11.1641 4.96314 11.177 4.96347 11.1899 4.96347C11.2028 4.96347 11.2157 4.96314 11.2285 4.96249H12.8324V15.3786C12.8324 15.6027 12.7434 15.8176 12.5849 15.9761C12.4265 16.1346 12.2115 16.2236 11.9874 16.2236H4.01235C3.78823 16.2236 3.5733 16.1346 3.41483 15.9761C3.25636 15.8176 3.16733 15.6027 3.16733 15.3786V4.96249H4.7713ZM4.05985 3.4625V2.61845C4.05985 1.99652 4.30692 1.40005 4.74669 0.960277C5.18647 0.520501 5.78293 0.273438 6.40487 0.273438H9.5949C10.2168 0.273438 10.8133 0.520501 11.2531 0.960277C11.6929 1.40005 11.9399 1.99652 11.9399 2.61845V3.4625H15.1774C15.5916 3.4625 15.9274 3.79828 15.9274 4.2125C15.9274 4.62671 15.5916 4.96249 15.1774 4.96249H14.3324V15.3786C14.3324 16.0005 14.0854 16.597 13.6456 17.0368C13.2058 17.4765 12.6094 17.7236 11.9874 17.7236H4.01235C3.39041 17.7236 2.79394 17.4765 2.35417 17.0368C1.91439 16.597 1.66733 16.0005 1.66733 15.3786V4.96249H0.822266C0.408052 4.96249 0.0722656 4.62671 0.0722656 4.2125C0.0722656 3.79828 0.408052 3.4625 0.822266 3.4625H2.41728H4.05985ZM6.40472 7.45042C6.81894 7.45042 7.15472 7.78621 7.15472 8.20042V12.9855C7.15472 13.3997 6.81894 13.7355 6.40472 13.7355C5.99051 13.7355 5.65472 13.3997 5.65472 12.9855V8.20042C5.65472 7.78621 5.99051 7.45042 6.40472 7.45042ZM10.3449 8.20042C10.3449 7.78621 10.0091 7.45042 9.59485 7.45042C9.18064 7.45042 8.84485 7.78621 8.84485 8.20042V12.9855C8.84485 13.3997 9.18064 13.7355 9.59485 13.7355C10.0091 13.7355 10.3449 13.3997 10.3449 12.9855V8.20042Z" fill="#97304A"/>
            </svg>
          </template>
        </URLInputFloatingV2>
        </div>
        <div :class="computeWeightClass(item)" class="border flex justify-center items-center rounded-[0.5rem] w-[4.5rem] h-[3.5rem]">
          <div class="weight_compute">
            <p class="text-[0.75rem] font-poppins text-[#757A8A]">Weight</p>
            <div class="text !text-black">
              <input :disabled="!getSaveLink.is_ab_test" :class="[item.weight && item.weight.toString().length == 3 ? 'w-[1.5rem]' : (item.weight && item.weight.toString().length == 2 ? 'w-[1.1rem]' : 'w-[0.6rem]')]" class="bg-transparent !appearance-none !border-none !h-5 p-0 " maxlength="2" @input="onChangeWeight($event,item)" v-model="item.weight" type="number" placeholder="10">
              <span>%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="toggle" class="flex mt-[0.5rem] justify-between items-center">
      <button type="button" :disabled="!getSaveLink.is_ab_test"  class="text !text-[#2961D2] cursor-pointer font-semibold" @click="addABTestingObject">+ Add New Destination Link</button>
      <div v-if="isAnyFieldInEditModeStatus || !isValidWeight(split_urls)" class="view_bottom_inner text-center">

        <Button
          id="login-button"
          type="button"
          class="bg-[#3C4549]"
          buttonClass="btn-md"
          @click="equalizeWeight"
          :disabled="isValidWeight(split_urls)"
          :title="isValidWeight(split_urls) ? 'You cannot use equalize weights option if the weights are set for your URLs are equal' : 'Distribute equal weight by clicking here'"
        >
          <template v-slot:label>Equalize</template>
        </Button>

      </div>
    </div>
  </div>
</template>
<script>
import { abTestingObjMessages, abTestingObjValidations } from '@/common/attributes'
import Button from '@/ui/ui-kit/v2/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ABTestingComponentV2',
  props: [
    'toggle',
    'split_urls',
    'campaignId',
    'campaign',
    'url',
    'self',
    'is_ab_test',
    'is_routing_rule'
    ],
  data() {
    return {
      isAnyFieldInEditModeStatus: false,
      abTestingObjMessages: abTestingObjMessages,
      debounce: null
    }
  },
  components: {
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    URLInputFloatingV2: () => import('@/ui/ui-kit/v2/URLInputFloatingV2.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
  },
  created () {
    // On updating ab testing we will reset the validations and loaders
    if (this.split_urls && this.split_urls.length > 1) {
      this.split_urls.forEach(item => {
        item.validations = { ...abTestingObjValidations }
        item.isEdit = false
        item.loader = false
        item.weight = parseInt(item.weight)
      })
    }
  },
  computed: {
    ...mapGetters['getSaveLink']
  },
  methods: {
    // In this method we all add the ab testing list item and we will not allow more than ten item list
    addABTestingObject () {
      if (this.split_urls.length >= 10) {
        this.$store.dispatch('toastNotification', { message: 'Only 10 splits are allowed.', type: 'error' })
        return
      }
      this.split_urls.push({
        weight: 0,
        url: '',
        validations: { ...abTestingObjValidations },
        isEdit: true,
        loader: false
      })
      this.isAnyFieldInEditMode()
    },
    // removing the list from ab testing object
    removeABTestingObject (itemIndex) {
      this.split_urls.splice(itemIndex, 1)
      this.isAnyFieldInEditMode()
      this.split_urls.forEach((item, index) => {
        this.weightValidations(item)
      })
    },
    // display the equalize button if any field in edit mode
    isAnyFieldInEditMode () {
      this.isAnyFieldInEditModeStatus = !!this.split_urls.filter(elem => elem.isEdit).length
    },
    // updating the item in ab testing object
    async updateABTestingObj (item, index, update = true) {
      if (!update) {
        item.isEdit = !item.isEdit
        this.isAnyFieldInEditMode()
        this.$forceUpdate()
        return
      }
      item.url = this.urlConcatenation(item.url)
      this.urlValidations(item)
      this.weightValidations(item)
      if (index && !this.requiredCheck(item.url) && this.isTypeCTA(this.campaignId, this.self)) await this.checkingIframe(item)
      let result = Object.keys(item.validations).every(k => item.validations[k] === false)
      if (result) {
        item.isEdit = !item.isEdit
        this.isAnyFieldInEditMode()
      }
      this.$forceUpdate()
    },
    onChangeUrLField (event, item) {
      this.urlValidations(item)
      if(this.isTypeCTA(this.campaignId, this.self)) {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          if(item.validations.url || item.validations.urlValid || item.validations.urlLength) return
          await this.checkingIframe(item)
        }, 600)
      }
    },
    // checking the url validations
    urlValidations (item) {
      item.url = item.url.trim()
      if (item.url && item.url.length > 8) {
        item.url = this.urlConcatenation(item.url)
        item.validations.urlLength = !this.maxLength(item.url, 500)
      }
      item.validations.urlValid = !this.isValidURL(item.url)
      item.validations.iframe = false
      this.weightValidations(item)
    },
    onChangeWeight (event, item) {
      this.weightValidations(item)
    },
    weightValidations (item) {
      item.weight = parseFloat(item.weight)
      item.validations.weight = !(item.weight > 0 && item.weight <= 100)
      item.validations.weightCriteria = !this.isValidWeight(this.split_urls)
    },
    computeWeightClass (item) {
      return item.validations.weight || item.validations.weightCriteria ? '!border-[#F4B740] bg-[#FFFEF9]' : '!border-[#B1E3C8] bg-[#EEF9F3]'
    },
    // Equalizing the weight of ab testing object
    equalizeWeight () {
      let splitData = this.splitInteger(100, this.split_urls.length)
      this.split_urls.forEach((item, index) => {
        item.weight = splitData[index]
        item.validations.weight = false
        item.validations.weightCriteria = false
      })
    },
    // Checking the iframe on url
    async checkingIframe (item) {
      item.loader = true
      this.$forceUpdate()
      const response = await this.checkLinkPreview(item.url, this.campaign)
      if (!response.data.status) {
        this.abTestingObjMessages.iframe = 'We can not show CTAs on this page.'
      }
      item.validations.iframe = !response.data.status
      item.loader = false
      this.$forceUpdate()
    },
    // splitting the integers in to equal chunks
    splitInteger (num, parts) {
      let val = 0
      let mod = num % parts
      let retData = 0
      if (mod == 0) {
        val = num / parts
        retData = Array(parts).fill(val)
      } else {
        val = (num - mod) / parts
        retData = Array(parts).fill(val)
        for (let i = 0; i < mod; i++) {
          retData[i] = retData[i] + 1
        }
      }
      return retData
    },
    /**
     * this method will validate the ab testing object
     */
    validateABTesting() {
      // here we are rechecking the validation of ab testing


        if (this.split_urls.length <= 1) {
          this.$store.dispatch('toastNotification', {
            message: 'At least two links are required for A/B Testing.',
            type: 'error'
          })
          return true

          this.split_urls = []
        }

        const validABValidation = this.split_urls
          .every((item) => Object.keys(item.validations).every(k => item.validations[k] === false))

        if (this.split_urls.length && !validABValidation) {
          this.$store.dispatch('toastNotification', {message: 'A/B Testing Validations failed.', type: 'error'})
          return true
        }

        const isValidWeight = this.isValidWeight(this.split_urls)
        if (this.split_urls.length && !isValidWeight) {
          this.$store.dispatch('toastNotification', {message: 'A/B Testing Validations failed.', type: 'error'})
          return true
        }

        if (this.split_urls.length <= 1) {
          this.split_urls = []
        }

        if (this.split_urls && this.split_urls.length > 0) {
          this.split_urls.forEach((item) => {
            delete item['validations']
            delete item['isEdit']
            delete item['loader']
          })
        }
        return false
    }
  },
  watch: {
    'campaignId' (value) {
      const isCta = this.isTypeCTA(value)
      this.split_urls.forEach((item, index) => {
        if (index === 0) {
          return
        }
        if (isCta) {
          this.checkingIframe(item)
        } else {
          item.validations.iframe = false
        }
      })
      this.$forceUpdate()
    }
    ,
    'url' (value) {
      if (this.split_urls.length === 0) {
        this.split_urls.push({
          weight: 100,
          url: this.url,
          validations: { ...abTestingObjValidations },
          isEdit: false,
          loader: false
        })
      }

      this.split_urls[0].url = value

    },
    'toggle' (value) {
      if (this.url && !this.split_urls.length) {
        this.split_urls.push({
          weight: 100,
          url: this.url,
          validations: { ...abTestingObjValidations },
          isEdit: false,
          loader: false
        })
      }
    },
    'split_urls' (value) {
      this.isAnyFieldInEditModeStatus = !!this.split_urls.filter(elem => elem.isEdit).length
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="less">
.weight_compute {
  div {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
}
</style>
